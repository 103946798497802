/**
 * String-related utility functions
 */

import Is = require("Everlaw/Core/Is");

import { StringUtil } from "design-system";

export const RECORD_SEPARATOR = String.fromCharCode(0x13);
export const INTERNAL_SEPARATOR = String.fromCharCode(0x11);

export const MAX_1_BYTE_UTF8_CODEPOINT = 0x7f;
export const MAX_2_BYTE_UTF8_CODEPOINT = 0x7ff;
export const MAX_3_BYTE_UTF8_CODEPOINT = 0xffff;
export const MAX_BMP_CODEPOINT = 0xffff;
export const MIN_HIGH_SURROGATE_CODEPOINT = 0xd800;
export const MAX_HIGH_SURROGATE_CODEPOINT = 0xdbff;
export const MIN_LOW_SURROGATE_CODEPOINT = 0xdc00;
export const MAX_LOW_SURROGATE_CODEPOINT = 0xdfff;

export function startsWith(str: string, substr: string) {
    // This might seems counterintuitive, but the point is to start at position 0 and check for
    // substr, moving back from there. Of course, there is nothing "back" of 0, so that's where the
    // search ends. The obvious `str.indexOf(substr) === 0` searches all of `str`, performing a lot
    // of unnecessary work.
    return str.lastIndexOf(substr, 0) === 0;
}

export function containsIgnoreCase(str: string, substr: string) {
    return str.toLowerCase().indexOf(substr.toLowerCase()) >= 0;
}

export function contains(str: string, substr: string): boolean {
    return str.indexOf(substr) >= 0;
}

export function containsAny(str: string, substrs: string[]): boolean {
    return substrs.some((val) => str.indexOf(val) >= 0);
}

export function endsWith(str: string, pattern: string, ignoreCase?: boolean): boolean {
    if (ignoreCase) {
        str = str.toLowerCase();
        pattern = pattern.toLowerCase();
    }
    var d = str.length - pattern.length;
    return d >= 0 && str.indexOf(pattern, d) === d;
}

export function isNullOrWhitespace(str: string) {
    return str == null || str.match(/^\s*$/) !== null;
}

/**
 * Returns str truncated so that it will encode to <= len UTF-8 bytes. Unpaired surrogate pairs are
 * treated as 3 UTF-8 bytes.
 *
 * Some tests:
 *
 *     expect("resume", truncatedToUtf8ByteLength("resume", 6));
 *     expect("resume", truncatedToUtf8ByteLength("resume", 7));
 *     expect("resum", truncatedToUtf8ByteLength("resume", 5));
 *     expect("resu", truncatedToUtf8ByteLength("resume", 4));
 *     expect("res", truncatedToUtf8ByteLength("resume", 3));
 *     expect("re", truncatedToUtf8ByteLength("resume", 2));
 *     expect("r", truncatedToUtf8ByteLength("resume", 1));
 *     expect("", truncatedToUtf8ByteLength("resume", 0));
 *     expect("", truncatedToUtf8ByteLength("", 0));
 *     expect("", truncatedToUtf8ByteLength("", 1));
 *     expect("", truncatedToUtf8ByteLength("résumé", 0)); // é is a 2-byte character
 *     expect("r", truncatedToUtf8ByteLength("résumé", 1));
 *     expect("r", truncatedToUtf8ByteLength("résumé", 2));
 *     expect("ré", truncatedToUtf8ByteLength("résumé", 3));
 *     expect("rés", truncatedToUtf8ByteLength("résumé", 4));
 *     expect("résu", truncatedToUtf8ByteLength("résumé", 5));
 *     expect("résum", truncatedToUtf8ByteLength("résumé", 6));
 *     expect("résum", truncatedToUtf8ByteLength("résumé", 7));
 *     expect("résumé", truncatedToUtf8ByteLength("résumé", 8));
 *     expect("résumé", truncatedToUtf8ByteLength("résumé", 9));
 *     expect("", truncatedToUtf8ByteLength("￮", 2));
 *     expect("￮", truncatedToUtf8ByteLength("￮", 3));
 *     expect("￮", truncatedToUtf8ByteLength("￮", 4));
 *     expect("", truncatedToUtf8ByteLength("👈", 3));
 *     expect("👈", truncatedToUtf8ByteLength("👈", 4));
 *     expect("👈", truncatedToUtf8ByteLength("👈", 5));
 */
export function truncatedToUtf8ByteLength(str: string, len: number) {
    let byteLength = 0;
    let i = 0;
    let byteInc: number;
    let iInc: number;
    do {
        // Since JavaScript is encoded as UTF-16, each individual character is at most 3x as long as
        // the corresponding UTF-8. This test allows us to short-circuit as soon as we've inspected
        // enough of the string to be sure that it's short enough.
        //
        // Importantly, when paired with the loop test, this test also ensures that i < str.length.
        if (byteLength + (str.length - i) * 3 <= len) {
            return str;
        }
        const c = str.charCodeAt(i);
        if (c <= MAX_1_BYTE_UTF8_CODEPOINT) {
            byteInc = 1;
            iInc = 1;
        } else if (c <= MAX_2_BYTE_UTF8_CODEPOINT) {
            byteInc = 2;
            iInc = 1;
        } else if (
            isHighSurrogate(c)
            && i + 1 < str.length
            && isLowSurrogate(str.charCodeAt(i + 1))
        ) {
            // We have a valid surrogate pair, which is always > MAX_3_BYTE_UTF8_CODEPOINT.
            byteInc = 4;
            iInc = 2;
        } else {
            // We either have a valid BMP character, which must be 3 UTF-8 bytes, or we have an
            // unpaired UTF-16 surrogate pair encoding. The latter possibility is an encoding error,
            // but if we're being permissive, we can represent it as 3 UTF-8 bytes, anyway.
            byteInc = 3;
            iInc = 1;
        }
        byteLength += byteInc;
        i += iInc;
    } while (byteLength <= len);
    // The character(s) starting at str.charAt(i - iInc) took us beyond our desired length.
    return str.substr(0, i - iInc);
}

export function truncatedTrim(str: string, len: number) {
    return str.trim().substr(0, len).trim();
}

export function ltrim(str: string, ch: string) {
    return trimInternal(str, ch, "");
}

export function rtrim(str: string, ch: string) {
    return trimInternal(str, "", ch);
}

export function trim(str: string, ch: string) {
    return trimInternal(str, ch, ch);
}

export function isLowSurrogate(cp: number) {
    return cp >= MIN_LOW_SURROGATE_CODEPOINT && cp <= MAX_LOW_SURROGATE_CODEPOINT;
}

export function isHighSurrogate(cp: number) {
    return cp >= MIN_HIGH_SURROGATE_CODEPOINT && cp <= MAX_HIGH_SURROGATE_CODEPOINT;
}

/**
 * Returns the Unicode code point for the given surrogate pair, assuming that isHighSurrogate(high)
 * and isLowSurrogate(low).
 */
export function surrogatePairToCodePoint(high: number, low: number) {
    return (
        0x10000
        + (high - MIN_HIGH_SURROGATE_CODEPOINT) * 0x400
        + (low - MIN_LOW_SURROGATE_CODEPOINT)
    );
}

export function trimInternal(str: string, lch: string, rch: string) {
    var len = str.length;
    var li = 0;
    var ri = len - 1;
    if (lch) {
        while (li < len && str[li] === lch) {
            li++;
        }
    }
    if (rch) {
        while (ri >= li && str[ri] === rch) {
            ri--;
        }
    }
    return str.substring(li, ri + 1);
}

export function unquoted(text: string) {
    var start = text[0] === '"' ? 1 : 0;
    if (text[text.length - 1] === '"') {
        text = text.slice(start, -1);
    } else if (start !== 0) {
        text = text.slice(start);
    }
    return text;
}

export function quoted(str: string) {
    return '"' + str + '"';
}

export function rsplit(str: string, substr: string, num?: number) {
    var l: string[] = [];
    var index = str.lastIndexOf(substr);
    var lastIndex = str.length;
    while (index >= 0 && (!Is.defined(num) || num-- > 0)) {
        var i = index + substr.length;
        l.push(str.substr(i, lastIndex - i));
        lastIndex = index;
        index = str.lastIndexOf(substr, index - 1);
    }
    l.push(str.substr(0, lastIndex));
    l.reverse();
    return l;
}

/* Converts a camel-cased string to a human readable one, via the following rule: all capital
 * letters are prefixed with a space, unless they are preceded by another capital letter.
 */
var camelToIR = function (s: string): string[] {
    s = capitalize(s);
    var blocks = s.match(/[A-Z0-9][^A-Z0-9]*/g);
    if (blocks === null) {
        // If there are no capital letters or numbers, just return a singleton array with the
        // original string.
        return [s];
    }
    var res: string[] = [];
    var acc: string[] = [];
    for (var i = 0; i < blocks.length; i++) {
        if (blocks[i].length === 1) {
            acc.push(blocks[i]);
        } else {
            if (acc.length > 0) {
                res.push(acc.join(""));
                acc = [];
            }
            res.push(blocks[i]);
        }
    }
    if (acc.length > 0) {
        res.push(acc.join(""));
    }
    return res;
};

export function camelToHuman(s: string) {
    return camelToIR(s).join(" ");
}

export function camelToSentenceCase(s: string) {
    return capitalize(camelToHuman(s).toLowerCase());
}

export function humanToCamel(s: string) {
    var ary = s.split(/\s+/g);
    if (ary.length === 0) {
        return "";
    }
    return ary[0].toLowerCase() + ary.slice(1).map(capitalize).join("");
}

export function humanToDashes(s: string): string {
    const ary = s.split(/\s+/g);
    if (ary.length === 0) {
        return "";
    }
    return ary.map((str) => str.toLowerCase()).join("-");
}

export function camelToDashes(s: string) {
    return camelToIR(s).join("-");
}

export function dashesToCamel(s: string) {
    var ary = s.split(/-/g);
    return ary[0].toLowerCase() + ary.slice(1).map(capitalize).join("");
}

export function snakeToHuman(s: string) {
    return s
        .split("_")
        .map((word) => word.toLocaleLowerCase())
        .join(" ");
}

/**
 * Takes an array of strings and creates a comma-separated list with an "and" joining the last two
 * elements (e.g., ["foo", "bar", "foobar", "barfoo"] -> "foo, bar, foobar, and barfoo").
 */
export function arrayToStringList(elems: string[], joiningWord = "and") {
    switch (elems.length) {
        case 0:
            return "";
        case 1:
            return elems[0];
        case 2:
            return elems.join(` ${joiningWord} `);
        default:
            const newElems = elems.slice(0, elems.length - 1);
            newElems.push(`${joiningWord} ` + elems[elems.length - 1]);
            return newElems.join(", ");
    }
}

/**
 * Escapes regex special characters in a string so that it can be safely concatenated with a regular
 * expression. See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
 */
export function escapeRegex(str: string) {
    return (str + "").replace(/[.*+?^=!:${}()|\[\]\/\\]/g, "\\$&");
}

export function escapeDoubleQuotes(str: string) {
    return (str + "").replace(/"/, "\\$&");
}

export function cleanFilename(str: string) {
    return str.replace(/[^\w .()-]/g, "_");
}

export function possessivePluralForm(str: string, count: number) {
    return count === 1 ? str + "'s" : str + "s'";
}

/**
 * Returns the substring of str that ends at end (or the end of str) and begins after the last
 * occurrence of beg that occurs completely before end (or the beginning of str).
 */
export function substrBetweenLast(beg: string, end: string, str: string) {
    if (!str) {
        return "";
    }
    var ei = str.lastIndexOf(end);
    if (ei < 0) {
        ei = str.length;
    }
    var bi = str.lastIndexOf(beg, ei - beg.length);
    return str.substring(bi < 0 ? 0 : bi + beg.length, ei);
}

/**
 * Returns the given decimal (between 0 and 1) as an integer percentage (0 to 100). If provided, the
 * cap is an integer value that puts an upper limit on the percentage (e.g., `Str.percent(1.0, 99)`
 * returns "99%").
 */
export function percent(decimal: number, cap?: number) {
    var percent = Math.round(decimal * 100);
    if (Is.defined(cap)) {
        percent = Math.min(percent, cap);
    }
    return percent + "%";
}

/**
 * Like percent, but rounds down, as is appropriate for a progress display.
 */
export function progress(decimal: number) {
    // NB: Math.floor gives certain undesirable results like Math.floor((58/100) * 100) === 57.
    // To handle these ratios nicely, we must work backwards in a way, finding the unique percent
    // such that (percent/100 <= decimal < (percent+1)/100).
    var percent = Math.round(decimal * 100);
    if (percent / 100 > decimal) {
        percent--;
    }
    return percent + "%";
}

// Repeats a string a number of times. For example, repeat("ab", 3) === "ababab"
export function repeat(str: string, count: number) {
    var targetLength = count * str.length;
    while (str.length < targetLength) {
        str += str;
    }
    return str.substr(0, targetLength);
}

/**
 * Whether a string is not defined or contains only whitespace.
 */
export function empty(s: string) {
    return !nonempty(s);
}

// Whether a string is defined and contains text other than whitespace.
export function nonempty(s: string) {
    return Is.defined(s) && s !== null && s.trim() !== "";
}

// Smart Quotes (TM) are bad: they look like real quotes but don't behave like them (at least as far
// as Lucene is concerned), creating confusing behaviors for users. We generally want to call this
// in the getValue() function for any kind of input that will be used for searches.
export function replaceSmartQuotes(s: string) {
    return s.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
}

// Returns a version of the given string with special characters for regular expressions escaped. See
// https://stackoverflow.com/questions/280793/case-insensitive-string-replacement-in-javascript/280805#280805
export function regexEscape(s: string) {
    return s.replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, "\\$1");
}

// return the largest string shared at the beginning of both input strings
export function commonStartString(s1: string, s2: string) {
    let i = 0;
    while (i < s1.length && i < s2.length && s1.charAt(i) === s2.charAt(i)) {
        i++;
    }
    return s1.substr(0, i);
}

/**
 * Ensure that a string is unique with respect to a lookup table, adding numeric strings at the
 * end if necessary.  Returned string is also added to the lookup table.
 */
export function ensureUniqueName(name: string, alreadySeen: { [s: string]: number }) {
    let newName = name;
    let numSeen = 1;
    if (alreadySeen[name]) {
        // start search at expected numSeen, but keep incrementing if it's already taken.
        for (numSeen = alreadySeen[name]; alreadySeen[newName]; numSeen++) {
            newName = name + " (" + numSeen + ")";
        }
        alreadySeen[name] = numSeen;
    }
    alreadySeen[newName] = 1;
    return newName;
}

export function lpad(s: string, len: number, padChar: string): string {
    for (let i = s.length; i < len; i++) {
        s = padChar + s;
    }
    return s;
}

export function isAscii(s: string) {
    return /^[\x00-\x7f]*$/.test(s);
}

export function toViewableAscii(s: string): string {
    return s.replace(/[^\x20-\x7e]/g, "");
}

export function isWindows1252(s: string) {
    const regexpr =
        /^[\u0000-\u007F\u00A0-\u00FF\u20AC\u201A\u0192\u201E\u2026\u2020\u2021\u02C6\u2030\u0160\u2039\u0152\u017D\u2018\u2019\u201C\u201D\u2022\u2013\u2014\u02DC\u2122\u0161\u203A\u0153\u017e\u0178]*$/;
    return regexpr.test(s);
}

export function isNumeral(s: string) {
    return /^[0-9]+$/.test(s);
}

export function isHexadecimal(s: string) {
    return /^[0-9a-fA-F]+$/.test(s);
}

export function getInitials(personName: string) {
    const names = personName.split(" ").filter((a) => a.length > 0);
    if (names.length === 0) {
        return "?";
    }
    const lastInitial = names.length > 1 ? names[names.length - 1].charAt(0).toUpperCase() : "";
    return names[0].charAt(0).toUpperCase() + lastInitial;
}

export function urlWithProtocol(url: string): string {
    return /^https?:\/\//.test(url) ? url : `https://${url}`;
}

// duplicated from `util/string.ts` in the design system because web workers were having issues with that import
// specifically, the way we use importScripts in WorkerManager.js cannot import from the design system or React

export function ellipsify(string: string, maxLength: number): string {
    if (!string) {
        return "";
    } else if (string.length <= maxLength) {
        return string;
    }
    return string.substring(0, maxLength - 1) + "…"; // unicode ellipsis
}

interface MiddleEllipsifyResult {
    path: string;
    isEllipsed: boolean;
}

/**
 * Helper method to ellipsify a path value such that the root folder and parent folder are always
 * shown. Ellipses the middle folders if the last subfolder is not displayed. Ellipses the root
 * folder, then the parent folder if the resulting string still exceeds the provided length. Ensures
 * at least four characters are present in ellipsified strings. Returns whether the path was
 * ellipsified.
 *
 * Examples of ellipsification:
 * 1. No ellipsification (if provided a .zip). Should be in format /filename
 *      /filename --> /filename
 * 2. No ellipsification (standard path):
 *      /rootFolder/parentFolder/fileName --> /rootfolder/parentFolder
 * 3. End ellipsification:
 *      /rootFolder/parentFolderExtraLongHere/fileName --> /root…/parentFolderExtraLon…
 * 4. Middle ellipsification:
 *      /rootFolder/grandParentFolder/parentFolder --> /rootFolder/…/parentFolder
 * 5. Middle + end ellipsification:
 *      /rootFolder/grandParentFolder/parentFolderExtraLongHere --> /root…/…/parentFolderExtraL…
 */
export function middleEllipsifyPath(path: string, maxLength: number): MiddleEllipsifyResult {
    // Trimming off name of the file from the path, along with the leading [/]
    const lastFolderIndex = path.lastIndexOf("/");
    if (lastFolderIndex === 0) {
        // No parent folders
        return { path: ellipsify(path, maxLength), isEllipsed: false };
    }
    const shortPath = path.substring(1, lastFolderIndex);

    let returnPath = shortPath;
    const ellipsed = shortPath.length > maxLength;

    if (ellipsed) {
        const firstFolderIndex = shortPath.indexOf("/", 0);
        let middleSegment = "/…";
        if (firstFolderIndex === -1) {
            // No subfolders
            return { path: ellipsify("/" + shortPath, maxLength), isEllipsed: ellipsed };
        } else if (firstFolderIndex === shortPath.lastIndexOf("/")) {
            // One subfolder
            middleSegment = "";
        }
        let firstSegment = shortPath.substring(0, firstFolderIndex);
        let lastSegment = shortPath.substring(shortPath.lastIndexOf("/"));
        let extraLength =
            firstSegment.length + middleSegment.length + lastSegment.length - maxLength;

        // If middle ellipsification was insufficient, ellipsify further.
        if (extraLength > 0) {
            // Root folder ellipsification is insufficient, ellipsify parent folder too.
            if (firstSegment.length - extraLength < 4) {
                extraLength = extraLength - firstSegment.length + 5;
                firstSegment = ellipsify(firstSegment, 5);
                lastSegment = ellipsify(lastSegment, lastSegment.length - extraLength);
            } else {
                firstSegment = ellipsify(firstSegment, firstSegment.length - extraLength);
            }
        }
        returnPath = firstSegment + middleSegment + lastSegment;
    }
    return { path: "/" + returnPath, isEllipsed: ellipsed };
}

/*
 * Breaks an input string into multiple lines and ellipsifies the last line if necessary.
 *
 * Removes spaces used for line breaks, but assumes any other weird spacing (leading or trailing
 * spaces, or several spaces in a row) is intentional.
 */
export function multiLineEllipsify(
    text: string,
    maxLines: number,
    maxCharactersPerLine: number,
): string[] {
    const ellipsified = [];
    for (let i = 0; i < maxLines - 1; i++) {
        if (text.length <= maxCharactersPerLine) {
            ellipsified.push(text);
            return ellipsified;
        }
        const breakIdx = text.lastIndexOf(" ", maxCharactersPerLine);
        if (breakIdx < 0) {
            ellipsified.push(ellipsify(text, maxCharactersPerLine));
            return ellipsified;
        } else {
            ellipsified.push(text.slice(0, breakIdx));
            text = text.slice(breakIdx + 1);
        }
    }
    ellipsified.push(ellipsify(text, maxCharactersPerLine));
    return ellipsified;
}

const RANDOM_STRING_CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

/**
 * Returns a random string of letters and numbers of the specified length.
 * @param length the length of the random string to return
 * @param sampleString the characters to use to generate the random string, defaults to alphanumeric
 */
export function randomString(length: number, sampleString = RANDOM_STRING_CHARS): string {
    let result = "";
    for (let i = 0; i < length; i++) {
        result += sampleString.charAt(Math.floor(Math.random() * sampleString.length));
    }
    return result;
}

export function num(n: number): string {
    return n.toLocaleString();
}

export function pluralForm(singular: string, count: number, plural = singular + "s"): string {
    return count === 1 || count === -1 ? singular : plural;
}

export function countOf(count: number, noun: string, plural: string = noun + "s"): string {
    return `${num(count)} ${pluralForm(noun, count, plural)}`;
}

export function capitalize(str: string): string {
    if (str === "") {
        return "";
    }
    return str[0].toUpperCase() + str.slice(1);
}

export function uncapitalize(string: string): string {
    if (string === "") {
        return "";
    }
    return string[0].toLowerCase() + string.slice(1);
}

/**
 * Removes all non-numeric characters from a string, including decimals and commas.
 * e.g. "abc123.8,<hello, world>45h" --> "123845". For use in a text-box that is Everlaw
 * admin facing only as we will need a dedicated component to display and verify local strings
 * for client facing number boxes.
 */
export function removeNonNumericChars(str: string): string {
    return str.replace(/\D/g, "");
}
