import ActionNode = require("Everlaw/UI/ActionNode");
import { EverColor } from "design-system";
import Dom = require("Everlaw/Dom");
import Is = require("Everlaw/Core/Is");

class Icon extends ActionNode {
    constructor(
        private iconClass: string,
        params: Icon.Params = {},
    ) {
        super(
            Dom.create(
                "span",
                {
                    class: "icon icon_" + iconClass,
                    role: "img",
                },
                params.parent,
            ),
            params,
        );
        if (Is.defined(params.alt)) {
            Icon.setAria(this.node, params.alt);
        } else {
            const alt = this.defaultLabels[iconClass];
            if (alt) {
                Icon.setAria(this.node, alt);
            } else if (Is.string(params.tooltip)) {
                Icon.setAria(this.node, params.tooltip); //TODO: explicitly give all Icons alts instead
            }
        }
    }

    /**
     * Change the icon image to something different. This lets you swap an icon
     * without inserting a new DOM node.
     */
    setIconClass(newIconClass: string): void {
        Dom.toggleClass(this.node, `icon_${this.iconClass}`, false);
        Dom.toggleClass(this.node, `icon_${newIconClass}`, true);
        this.iconClass = newIconClass;
    }

    /**
     * Update the icon node to use the white variant.
     * Warning: if there is no matching icon with the `_white` suffix, the results will
     * not be well defined.
     */
    setWhite(state = true): void {
        Dom.toggleClass(this.node, "icon_" + this.iconClass, !state);
        Dom.toggleClass(this.node, "icon_" + this.iconClass + "-white", state);
    }

    /** default accessible labels for some common Icons */
    private defaultLabels = {
        "alert-circle-red": "alert",
        "alert-circle-red-20": "alert",
        "alert-circle-white": "alert",
        "alert-circle-20": "alert",
        "sparkle-blue-16": "auto-code active",
        database: "size",
        file: "number of documents",
        download: "download",
        "info-circle-filled-20": "info",
        "info-circle-20": "info",
        "info-circle-white-20": "info",
        "bolt-filled-blue-16": "smart search", // TODO: set alt in EQL, SearchTerms, BaseSelect instead of here.
        pencil: "edit",
        search: "search",
        "search-filled": "search",
        share: "share",
        "share-white": "share",
        dots: "more options",
        trash: "delete",
        "alert-triangle-red-20": "warning",
        "alert-triangle-20": "warning",
        x: "close",
        "x-red": "remove",
        "x-white": "close",
    };

    spin(spin = true): void {
        // Edge has a bug where the animation aren't removed when the CSS class is removed.
        Dom.toggleClass(this.node, "no-animation", !spin);
        Dom.toggleClass(this.node, "spinning", spin);
    }
}

module Icon {
    export interface Params extends Omit<ActionIconParams, "onClick"> {}

    export interface ActionIconParams extends ActionNode.Params {
        /** accessible label */
        alt?: string;
        parent?: Dom.Nodeable | Node;
    }

    /**
     * @deprecated Clickable icons should generally use the Button.IconButton class instead,
     * except in certain special situations or one-off cases cleared by a designer.
     *
     * At the time of writing, some places where ActionIcons appear are "badges" (or "chips",
     * as they are called in Bluebook), expanders, and dark backgrounds where IconButtons don't
     * look right. As chips and expander components are created in Bluebook and UI refreshes
     * are performed, the number of occurrences of ActionIcon should go down.
     */
    export class ActionIcon extends Icon {
        constructor(iconClass: string, params: Icon.ActionIconParams = {}) {
            super(iconClass, params);
            this.node.setAttribute("role", "button");
        }
    }

    /**
     * Returns a (flexbox) div with the specified icon in the left-hand margin and the specified content
     * properly indented. When useBackground is true, our background color is applied to the div.
     */
    export function callout(
        iconName: string,
        content: Dom.Content,
        bgColor = true,
        alt?: string,
    ): HTMLDivElement {
        const ret = Dom.div({ class: "icon-callout", role: "status" }, [
            Dom.div({ class: "icon-div" }, Dom.node(new Icon(iconName, { alt }))),
            Dom.div({ class: "content-div" }, content),
        ]);
        // When explicitly applying our bg color, we need padding between the border and the content.
        bgColor
            && Dom.style(ret, {
                backgroundColor: EverColor.PARCHMENT_30,
                padding: "10px",
                boxShadow: "inset 0 0 0 1px " + EverColor.PARCHMENT_50,
            });
        return ret;
    }

    /**
     * Add an alt to any html element the same way you would use the alt tag of an img.
     */
    export function setAria(elem: HTMLElement, alt: string): void {
        Dom.setAttr(elem, "aria-label", alt);
        if (alt === "") {
            Dom.setAttr(elem, "aria-hidden", "true");
        } else {
            Dom.removeAttr(elem, "aria-hidden");
        }
    }
}

export = Icon;
