import * as Widget from "Everlaw/UI/Widget";
import * as Dom from "Everlaw/Dom";
import * as React from "react";
import { createRoot, Root } from "react-dom/client";

/**
 * Wraps a React component to interface with our exising widget system. As much functionality as
 * possible should be contained in the component itself. Derived classes should only contain what's
 * needed to interface with the React component.
 */
export class ReactWidget<Props = Record<string, unknown>> extends Widget {
    /**
     * Props can be read here, but must only be modified with `updateProps`.
     */
    protected props: Props;

    private readonly root: Root;

    constructor(private component: React.ComponentType<Props>) {
        super();
        this.node = Dom.div({ class: "react-root" });
        this.root = createRoot(this.node);
        this.registerDestroyable(() => {
            this.root.unmount();
            Dom.destroy(this.node);
        });
    }

    /**
     * Initialize props used in `component` and trigger the initial render. This should be called in
     * the constructor and must be called before any call to `updateProps`.
     */
    protected initProps(props: Props): void {
        this.props = { ...props };
        this.render();
    }

    /**
     * Update a subset of props and rerender the component. Unspecified props remain the same.
     * This will always trigger a rerender, even if no props have changed.
     */
    updateProps(props: Partial<Props>): void {
        this.props = { ...this.props, ...props };
        this.render();
    }

    protected render(): void {
        this.root.render(React.createElement(this.component, this.props));
    }

    /**
     * Does nothing in the base ReactWidget.
     */
    override focus(): void {
        return;
    }

    /**
     * Does nothing in the base ReactWidget.
     */
    override blur(): void {
        return;
    }
}

/**
 * Convenience function for creating a simple ReactWidget instance for a given React component.
 * More complex use cases may require creating a new class extending ReactWidget.
 *
 * Useful for rendering React components/tabs/pages as children of non-React elements.
 *
 * @param component React component to wrap in ReactWidget.
 * @param props Initial props to pass to the React component.
 */
export function wrapReactComponent<ComponentProps = Record<string, unknown>>(
    component: React.ComponentType<ComponentProps>,
    props: ComponentProps,
): ReactWidget<ComponentProps> {
    return new (class extends ReactWidget<ComponentProps> {
        constructor(props: ComponentProps) {
            super(component);
            this.initProps(props);
            this.render();
        }
    })(props);
}
