import Dom = require("Everlaw/Dom");
import dijit_Tooltip = require("dijit/Tooltip");
import dojo_mouse = require("dojo/mouse");
import dojo_on = require("dojo/on");
import { Tooltip as ReactTooltipComponent, TooltipProps } from "design-system";
import { FocusDiv } from "Everlaw/UI/FocusDiv";
import { ReactWidget } from "Everlaw/UI/ReactWidget";

/**
 * This class is a lightweight version of dijit/Tooltip - by avoiding the dijit/_Widget overhead,
 * it takes about 1/5 the time to create.
 */
class Tooltip {
    /** Number of milliseconds to wait after hovering over/focusing on the object, before
     * the tooltip is displayed. */
    showDelay = 400;
    /** Number of milliseconds to wait after unhovering the object, before
     * the tooltip is hidden.  Note that blurring an object hides the tooltip immediately. */
    hideDelay = 50;
    /** See description of `dijit/Tooltip.defaultPosition` for details on position parameter. */
    position: string[];
    /** This flag will disable the tooltip! */
    disabled = false;
    domNode = document.createElement("div");
    state = Tooltip.State.DORMANT;
    private _connections: dojo_on.Handle[];
    private _connectNode: HTMLElement; // tooltip currently displayed for this node
    private _showTimer: number;
    private _hideTimer: number;
    constructor(
        nodelike: Dom.Nodeable,
        msg?: Dom.Content,
        position?: string[],
        public manual = false,
    ) {
        const node = Dom.node(nodelike);
        this._connections = this.manual
            ? []
            : [
                  dojo_on(node, dojo_mouse.enter, () => {
                      this._onHover(node);
                  }),
                  dojo_on(node, "focusin", () => {
                      this._onHover(node);
                  }),
                  dojo_on(node, dojo_mouse.leave, () => {
                      this._onUnHover();
                  }),
                  dojo_on(node, "focusout", () => {
                      this._setState(Tooltip.State.DORMANT);
                  }),
              ];
        this.position = position || ["below-centered", "above-centered"];
        this.setContent(msg);
    }
    getHTMLContent(): Dom.Content | null {
        return this.domNode.innerHTML;
    }
    setContent(content: Dom.Content) {
        Dom.setContent(this, content);
    }
    private _setState(val: Tooltip.State) {
        // If this tooltip is disabled, override whatever state was requested with the DORMANT state.
        val = this.disabled ? Tooltip.State.DORMANT : val;

        if (
            this.state === val
            || (val === Tooltip.State.SHOW_TIMER && this.state === Tooltip.State.SHOWING)
            || (val === Tooltip.State.HIDE_TIMER && this.state === Tooltip.State.DORMANT)
        ) {
            return;
        }

        if (this._hideTimer) {
            clearTimeout(this._hideTimer);
            delete this._hideTimer;
        }
        if (this._showTimer) {
            clearTimeout(this._showTimer);
            delete this._showTimer;
        }

        switch (val) {
            case Tooltip.State.DORMANT:
                if (this._connectNode) {
                    dijit_Tooltip.hide(this._connectNode);
                    delete this._connectNode;
                    this.onHide();
                }
                break;
            case Tooltip.State.SHOW_TIMER: // set timer to show tooltip
                // should only get here from a DORMANT state, i.e. tooltip can't be already SHOWING
                this._showTimer = window.setTimeout(() => {
                    if (this._connectNode && this._connectNode.parentNode) {
                        this._setState(Tooltip.State.SHOWING);
                    }
                }, this.showDelay);
                break;
            case Tooltip.State.SHOWING: // show tooltip and clear timers
                var content = this.getHTMLContent();
                if (!content) {
                    this._setState(Tooltip.State.DORMANT);
                    return;
                }

                var mouseenter = this.manual
                    ? null
                    : () => {
                          this._setState(Tooltip.State.SHOWING);
                      };
                var mouseleave = this.manual
                    ? null
                    : () => {
                          this._setState(Tooltip.State.HIDE_TIMER);
                      };
                // Show tooltip and setup callbacks for mouseenter/mouseleave of tooltip itself
                dijit_Tooltip.show(
                    content,
                    this._connectNode,
                    this.position,
                    null,
                    null,
                    mouseenter,
                    mouseleave,
                );

                this.onShow(this._connectNode, this.position);
                break;
            case Tooltip.State.HIDE_TIMER: // set timer set to hide tooltip
                this._hideTimer = window.setTimeout(() => {
                    this._setState(Tooltip.State.DORMANT);
                }, this.hideDelay);
                break;
        }

        this.state = val;
    }
    /** Despite the name of this method, it actually handles both hover and focus
     *  events on the target node, setting a timer to show the tooltip. */
    private _onHover(target: HTMLElement) {
        if (this._connectNode && target !== this._connectNode) {
            // Tooltip is displaying for another node
            this._setState(Tooltip.State.DORMANT);
        }
        this._connectNode = target;
        this._setState(Tooltip.State.SHOW_TIMER); // no-op if show-timer already set, or if already showing
    }
    /** Handles mouseleave event on the target node, hiding the tooltip. */
    private _onUnHover() {
        this._setState(Tooltip.State.HIDE_TIMER); // no-op if already dormant, or if hide-timer already set
    }
    /** Display the tooltip; usually not called directly. */
    open(target: HTMLElement) {
        this._setState(Tooltip.State.DORMANT);
        this._connectNode = target;
        this._setState(Tooltip.State.SHOWING);
    }
    /** Hide the tooltip or cancel timer for show of tooltip */
    close() {
        this._setState(Tooltip.State.DORMANT);
    }
    /** Called when the tooltip is shown */
    onShow(target: HTMLElement, position: string[]) {}
    /** Called when the tooltip is hidden */
    onHide() {}
    destroy() {
        clearTimeout(this._showTimer);
        delete this._showTimer;
        this._setState(Tooltip.State.DORMANT);
        this._connections.forEach((handle) => {
            handle.remove();
        });
    }
}

module Tooltip {
    export const enum State {
        DORMANT, // tooltip not SHOWING
        SHOW_TIMER, // tooltip not SHOWING but timer set to show it
        SHOWING, // tooltip displayed
        HIDE_TIMER, // tooltip displayed, but timer set to hide it
    }

    /**
     * Creates a tooltip that's only displayed when the reference node is ellipsed. The tooltip's HTML
     * matches that of `params.mirror`, which defaults to `node`. The other params can be used to
     * override dijit/Tooltip properties (e.g., position).
     */
    export class FlexibleMirrorTooltip extends Tooltip {
        private mirrorNode: HTMLElement;
        private refNode: HTMLElement;
        constructor(
            nodelike: Dom.Nodeable,
            mirror?: Dom.Nodeable,
            ref?: Dom.Nodeable,
            position?: string[],
        ) {
            super(nodelike, null, position || ["below", "above"]);
            this.mirrorNode = Dom.node(mirror || nodelike);
            this.refNode = Dom.node(ref || nodelike);
        }
        protected getRawHTMLContent(): HTMLElement {
            let ref = this.refNode,
                block = ref;
            const mirror = this.mirrorNode;
            if (isBeyondClipThreshold(block)) {
                return mirror;
            }
        }
        override getHTMLContent(): Dom.Content | null {
            const htmlContent = this.getRawHTMLContent();
            return htmlContent ? htmlContent.innerHTML : null;
        }
    }

    /**
     * Same as FlexibleMirrorTooltip except that the referenced node is the same as the mirror node. So
     * if the mirror node is ellipsed then a tooltip will be created with the mirror node's contents.
     */
    export class MirrorTooltip extends FlexibleMirrorTooltip {
        constructor(
            nodelike: Dom.Nodeable,
            mirror?: Dom.Nodeable,
            position?: string[],
            public contentCleaner?: (node: HTMLElement) => Dom.Content,
        ) {
            super(nodelike, mirror, mirror, position);
        }

        override getHTMLContent(): Dom.Content | null {
            const htmlContent = super.getRawHTMLContent();
            if (this.contentCleaner && htmlContent) {
                // clone and wrap node so as to not modify the underlying refNode
                return this.contentCleaner(Dom.div((htmlContent as HTMLElement).cloneNode(true)));
            } else {
                return htmlContent ? htmlContent.innerHTML : null;
            }
        }
    }

    export function setFlexibleDescription(
        node: HTMLElement,
        content: Dom.Content,
        mirror: Dom.Nodeable,
        ref: Dom.Nodeable,
        position?: string[],
    ) {
        Dom.setContent(node, content);
        return new FlexibleMirrorTooltip(node, mirror, ref, position);
    }

    /**
     * It is common to set the text for a node and to create a tooltip with the same text (so that the
     * user can see it when it is ellipsed). This function takes a node (which will typically have the
     * `description` class set; this function won't set it) and the text to set on that node. It sets
     * the text and creates the tooltip.
     *
     * Returns the tooltip, so that it can be correctly destroyed.
     */
    export function setDescription(
        node: HTMLElement,
        content: Dom.Content,
        mirror?: Dom.Nodeable,
        position?: string[],
    ) {
        Dom.setContent(node, content);
        return new MirrorTooltip(node, mirror, position);
    }

    /**
     * Returns true iff the given element (or its nearest ancestor with width) has scrollWidth exceeding
     * clientWidth, or scrollHeight greater than 1px more than clientHeight. In other words, returns
     * true if there is any horizontal scroll, or 2px or more of vertical scroll.
     */
    export function isBeyondClipThreshold(block: HTMLElement) {
        while (!block.clientWidth) {
            block = block.parentElement;
            if (!block) {
                return false;
            }
        }
        // don't show tooltip if there is just 1px of vertical clipping but if the width or height
        // of the element exceeds its container then show the tooltip
        return block.scrollWidth > block.clientWidth || block.scrollHeight > block.clientHeight + 1;
    }

    /**
     * A wrapper widget for React Tooltips from Bluebook design system. If possible, the
     * [Tooltip]{@link ReactTooltipComponent} component from "design-system" should be used instead.
     * Use this widget if you need a React tooltip in a place that is impractical to completely convert
     * to React.
     *
     * When specifying the `target` prop, the target element should be wrapped in an object like the
     * following: `{ current: targetElement }`.
     *
     * When specifying the `children` prop (a.k.a. the tooltip's contents), a string can be passed in
     * for simple cases. `ReactNode`s can be passed in for more complex cases via JSX/TSX or through
     * `React.createElement`. To pass more than one element to children, wrap everything in a
     * `React.Fragment`.
     */
    export class ReactTooltip extends ReactWidget<TooltipProps> {
        constructor(props: TooltipProps) {
            super(ReactTooltipComponent);
            this.initProps(props);
            this.render();
        }
    }
}

export = Tooltip;
