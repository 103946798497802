import C = require("Everlaw/Constants");
import Is = require("Everlaw/Core/Is");
import Str = require("Everlaw/Core/Str");
import Dom = require("Everlaw/Dom");
import E = require("Everlaw/Entities");
import { StringUtil } from "design-system";
import { objectToQuery } from "Everlaw/Core/Obj";

/**
 * Provides a way for users to avoid accidentally closing the browser when there is ongoing
 * activity, e.g. uploading.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
 * for custom text support across different browsers. As of this writing, only IE supports it.
 */
export function warnOnUnload(numActive: () => number, singular: string) {
    addEventListener("beforeunload", (e) => {
        var count = numActive();
        if (count === 0) {
            // Don't show a prompt.
            return;
        }
        e.returnValue = countOf(count, singular) + " currently in progress.";
        return e.returnValue;
    });
}

/**
 * Sets the focus targets for the specified skip buttons, makes those target elements focusable,
 * and unhides the skip buttons.
 *
 * Generally, this method should be called during the initialization of a page.
 *
 * @param menuId: The element id for the focus target of the "Skip to menu" button
 * @param contentId: The element id for the focus target of the "Skip to content" button
 * @param hideIfUnused: When true, hides the corresponding skip buttons for unprovided element ids.
 * For example, if {@code menuId} is null, the "Skip to menu" button will be hidden.
 */
export function setSkipButtons(menuId?: string, contentId?: string, hideIfUnused = true) {
    setSkipButton("skip-to-menu", menuId, hideIfUnused);
    setSkipButton("skip-to-content", contentId, hideIfUnused);
}

function setSkipButton(buttonId: string, targetId?: string, hideIfUnused = true) {
    const button = Dom.byId(buttonId);
    if (targetId) {
        const target = Dom.byId(targetId);
        target.tabIndex = -1;
        button.onclick = () => target.focus();
        Dom.show(button);
    } else if (hideIfUnused) {
        Dom.hide(button);
    }
}

/**
 * Focuses on the skip-buttons div, which ensures that the skip buttons can be focused by
 * tabbing once.
 */
export function resetFocus() {
    Dom.byId("skip-buttons").focus();
}

/**
 * Deletes the given keys from the given params object, citing the specified reason if one is
 * provided. If a caller does not care to provide a reason, params can be provided as the first
 * argument.
 */
export function deleteParams(reason: string, params: any, ...keys: string[]): void;
export function deleteParams(params: any, ...keys: string[]): void;
export function deleteParams(reason: any, params: any /*, keys... */) {
    var start: number;
    if (Is.string(reason)) {
        // reason provided
        start = 2;
        reason = " because " + reason;
    } else {
        // params is actually the first argument
        start = 1;
        params = reason;
        reason = "";
    }
    for (var i = start; i < arguments.length; i++) {
        var key = arguments[i];
        if (params[key]) {
            console.log("Deleting params." + key + reason);
            delete params[key];
        }
    }
}

/**
 * Returns val/total as a percent rounded to numDigits after the decimal. It might seem strange
 * for total to be the first argument, but it's convenient for when using this as a partially
 * applied function.
 */
export function percentOf(total: number, val: number, numDigits = 1): string {
    const pct = (val / total) * 100;
    const zero = 0;
    const hundred = 100;
    let rounded = pct.toFixed(numDigits);
    if (rounded === zero.toFixed(numDigits) && pct > 0) {
        if (numDigits === 0) {
            rounded = "< 1";
        } else if (numDigits === 1) {
            rounded = "< 0.1";
        } else {
            rounded = "< " + zero.toFixed(numDigits - 1) + "1";
        }
    } else if (rounded === hundred.toFixed(numDigits) && pct < 100) {
        if (pct < 100) {
            if (numDigits === 0) {
                rounded = "> 99";
            } else {
                rounded = "> 99.";
                for (let i = 0; i < numDigits; i++) {
                    rounded += "9";
                }
            }
        } else if (pct === 100) {
            rounded = "100";
        }
    }
    return rounded + "%";
}

export function toInt(str: string) {
    var num = parseInt(str, 10);
    if (isNaN(num)) {
        return null;
    }
    return num;
}

export function dashed(a: string, b: string) {
    return a === b ? a : `${a} ${E.NDASH} ${b}`;
}

export function dashedAndLineBroken(a: string, b: string) {
    return a === b ? [a] : [a + " " + E.NDASH, Dom.br(), b];
}

/**
 * Assumes n is an integer and 0 <= n < 100
 */
export function twoDigit(n: number) {
    if (n < 10) {
        return "0" + n;
    }
    return String(n);
}

export function ordinal(num: number) {
    // 10-19, 110-119, etc should all use "th"
    if (Math.floor(num / 10) % 10 === 1) {
        return num + "th";
    }
    switch (num % 10) {
        case 1:
            return num + "st";
        case 2:
            return num + "nd";
        case 3:
            return num + "rd";
        default:
            return num + "th";
    }
}

/**
 * Returns obj[key], or if obj[key] is undefined, sets obj[key] to a default value and returns that
 * value.
 *
 * @param obj           an Object
 * @param key           the key to lookup in obj
 * @param defaultVal    either a default value or a function(key) that will return a default value
 */
export function getDefault<K extends string | number, V>(
    obj: { [key: string]: V },
    key: K,
    defaultVal: V | ((key: K) => V),
): V;
export function getDefault(obj: any, key: any, defaultVal: any) {
    if (Is.defined(obj[key])) {
        return obj[key];
    } else {
        return (obj[key] = Is.func(defaultVal) ? defaultVal(key) : defaultVal);
    }
}

export function reviewURL(project_id: number, doc_id: number) {
    return "/" + project_id + "/review.do#doc=" + doc_id;
}

export function getJsonFromUrl(url?: string): any {
    if (url == null) {
        url = location.href;
    }
    const question = url.indexOf("?");
    let hash = url.indexOf("#");
    if (hash === -1 && question === -1) {
        return {};
    }
    if (hash === -1) {
        hash = url.length;
    }
    const query =
        question === -1 || hash === question + 1
            ? url.substring(hash + 1)
            : url.substring(question + 1, hash);
    const result: Record<string, any> = {};
    query.split("&").forEach((part) => {
        if (!part) {
            return;
        }
        part = part.split("+").join(" "); // replace every + with space, regexp-free version
        const eq = part.indexOf("=");
        let key = eq > -1 ? part.substr(0, eq) : part;
        const val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : "";
        const from = key.indexOf("[");
        if (from === -1) {
            result[decodeURIComponent(key)] = val;
        } else {
            const to = key.indexOf("]", from);
            const index = decodeURIComponent(key.substring(from + 1, to));
            key = decodeURIComponent(key.substring(0, from));
            if (!result[key]) {
                result[key] = [];
            }
            if (!index) {
                result[key].push(val);
            } else {
                result[key][index] = val;
            }
        }
    });
    return result;
}

/**
 * Returns the single argument passed to it.
 */
export function identity<T>(x: T) {
    return x;
}

/**
 * Returns a function(val) that will set obj[attr] to the value with which it is called.
 */
export function setter<V>(obj: { [attr: string]: V }, attr: string) {
    return function (val: V) {
        obj[attr] = val;
    };
}

export function nop() {}

export function onHomePage(): boolean {
    return Str.endsWith(location.pathname, "home.do");
}

export function onReviewPage(): boolean {
    return Str.endsWith(location.pathname, "review.do");
}

export function onSearchPage(): boolean {
    return Str.endsWith(location.pathname, "search.do");
}

export function onSuperuserPage(): boolean {
    return Str.endsWith(location.pathname, "superuser.do");
}

export function onAdminPage(): boolean {
    return Str.endsWith(location.pathname, "admin.do");
}

export function onOrgAdminPage(): boolean {
    return Str.endsWith(location.pathname, "org.do");
}

export function onProjectSettingsPage(): boolean {
    return Str.endsWith(location.pathname, "settings.do");
}

export function onSearchTermReportPage(): boolean {
    return Str.endsWith(location.pathname, "searchTermReport.do");
}

export function onAnalyticsPage(): boolean {
    return Str.endsWith(location.pathname, "analytics.do");
}

export function onUploadsPage(): boolean {
    return Str.endsWith(location.pathname, "data.do");
}

export function onChronologyPage(): boolean {
    return Str.endsWith(location.pathname, "chron.do");
}

export function onClusteringPage(): boolean {
    return Str.endsWith(location.pathname, "clustering.do");
}

export function onAdminOrSuperuserPage(): boolean {
    return onSuperuserPage() || onAdminPage() || onOrgAdminPage();
}

/**
 * Gets the page we are on for use by google analytics
 * Only works for pages that end in .do
 */
export function getPageName(): string {
    if (!Str.endsWith(location.pathname, ".do")) {
        return "";
    }
    const page = location.pathname.split("/").pop();
    return page?.slice(0, page.lastIndexOf(".")) || "";
}

export function currentPageWithHash(hash: any) {
    const hashString = objectToQuery(hash);
    return `${window.location.pathname}${window.location.search}#${hashString}`;
}

/**
 * Return a GUID based on the time client side.
 * Take from stack overflow answer:
 * http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
 * @returns a GUID string
 */
export function getGUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export type Destroyable =
    // any function
    | { (): void }
    // a few generic methods
    | { destroy(): void }
    | { remove(): void }
    // a few generic properties
    | { tooltip: { destroy(): void } }
    | { connect: { remove(): void } }
    | { connects: { remove(): void }[] }
    | DestroyableArray;
interface DestroyableArray extends Array<Destroyable> {}

/**
 * A generic destroyer. Note that a Destroyable is both a SingleDestroyable and an arbitrary nesting
 * of them, so this works for Destroyable[] values as well.
 */
export function destroy(elem: Destroyable): void;
export function destroy(elem: any) {
    if (elem) {
        if (Is.array(elem)) {
            elem.forEach(destroy);
        } else if (Is.func(elem)) {
            elem();
        } else if (elem.destroy) {
            elem.destroy();
        } else if (elem.remove && !(elem instanceof Node)) {
            // The ! (elem instanceof Node) check is because we should never need to remove Dom
            // elements in this method and it causes errors in IE where remove() is not supported.
            elem.remove();
        } else {
            // Icons, or other output that has either a tooltip or a connection (or both)
            if (elem.tooltip) {
                elem.tooltip.destroy();
            }
            if (elem.connect) {
                elem.connect.remove();
            }
            if (elem.connects && Is.array(elem.connects)) {
                elem.connects.forEach(function (c: any) {
                    c.remove();
                });
            }
        }
    }
}

export function randomInt() {
    return Math.floor(Math.random() * 4294967296) - 2147483648; // Random id from -2**31 to 2**31 - 1
}

export function randomElement<T>(arr: T[]) {
    return arr[Math.floor(Math.random() * arr.length)];
}

export function logStackTrace() {
    var e: any = new Error("dummy");
    var stack = e.stack
        .replace(/^[^\(]+?[\n$]/gm, "")
        .replace(/^\s+at\s+/gm, "")
        .replace(/^Object.<anonymous>\s*\(/gm, "{anonymous}()@");
    console.log(stack);
}

/*
 * Format/abbreviate num for display using Math.abs(num) to handle negative numbers, and prepending
 * a "-" to the results for negative nums.
 * 0-999: as is (fractions get rounded)
 * 1_000-9_999: 0.0k (e.g. 1.9k)
 * 10_000-999_999: 0k (e.g. 10k, 100k)
 * 1_000_000-9_999_999: 0.0M
 * 10_000_000+: 0M
 */
export function displayNumberAbbr(
    num: number,
    base2 = false,
    places?: number,
    decimalPlaces?: number,
    useMetricPrefix = false,
    addSpace = false,
) {
    return StringUtil.displayNumber(num, {
        base2,
        places,
        decimalPlaces,
        useMetricUnit: useMetricPrefix,
        addSpace,
    });
}

export function displayFileSize(
    bytes: number,
    base2 = false,
    places?: number,
    decimalPlaces?: number,
    addSpace = true,
): string {
    return StringUtil.displaySize(bytes, {
        base2,
        places,
        decimalPlaces,
        addSpace,
    });
}

/**
 * Takes in a list of [condition, text] and constructs a single string composed of each text piece
 * separated by a bullet point. Only includes text where the condition is true.
 */
export function buildBulletSeparatedText(conditionalText: [boolean, string][]): string {
    const stringElems: string[] = [];
    conditionalText.forEach((elem) => {
        elem[0] && stringElems.push(elem[1]);
    });
    return stringElems.join(" • ");
}

export interface GBOnlyFileSizeDisplay {
    // display is rounded up the nearest GB, tooltip is GB to third decimal place to display on hover
    rounded: number;
    display: string;
    tooltip: string;
}

/**
 * Returns the given size rounded according to billing rules: Round up to first whole GB, and from
 * then on round to nearest GB.
 */
export function billingRoundedSize(bytes: number): { quot: number; rounded: number } {
    const quot = bytes / C.GB;
    const rounded = 1 > quot && quot > 0 ? Math.ceil(quot) : Math.round(quot);
    return { quot, rounded };
}

/**
 * Returns file size in GB for display on Database Sizes page
 *
 * Matches the formatting when sending billing spillover messages in
 * MessageService.java#sendSpilloverDeletionMessage
 * @param bytes the billable size to convert to a displayable size
 */
export function displayFileSizeGBOnly(bytes: number): GBOnlyFileSizeDisplay {
    const { quot, rounded } = billingRoundedSize(bytes);
    const unformattedTooltip = numWithPlaces(quot, 3) + " GB";
    const tooltip =
        unformattedTooltip === "0.000 GB" && bytes > 0 ? "0.001 GB" : unformattedTooltip;
    const display = StringUtil.num(rounded) + " GB";
    return { rounded, display, tooltip };
}

export function displayBillingFileSize(bytes: number, round = false, numDigits = 1): string {
    const { quot, rounded } = billingRoundedSize(bytes);
    if (round) {
        return rounded + " GB";
    }
    return Number(quot.toFixed(numDigits)) + " GB";
}

export function displayBillingSizeDiff(bytes: number, places = 0): string {
    const quot = billingRoundedSize(bytes).quot;
    const withPlaces = Number(quot.toFixed(places));
    if (quot === 0) {
        return "0";
    }
    if (Math.abs(quot) < 0.01) {
        return quot > 0 ? "<0.01" : " - <0.01";
    }
    const sign = quot > 0 ? "+ " : "- ";
    return sign + Math.abs(withPlaces);
}

/**
 * @deprecated New code should use StringUtil.num() directly. For some reason,
 * dojo_number.format(undefined) and dojo_number.format(NaN) returned null, and
 * dojo_number.format(null) returned 0. Some old code expects this behavior, so this function
 * adheres to those rules for that code.
 */
export function num(count: number, options?: Intl.NumberFormatOptions): string | null {
    if (count === undefined || isNaN(count)) {
        return null;
    } else if (count === null) {
        return "0";
    }
    return StringUtil.num(count, options);
}

/**
 * @deprecated New code should use StringUtil.numWithPlaces() directly.
 */
export function numWithPlaces(count: number, places: number): string | null {
    return num(count, { minimumFractionDigits: places, maximumFractionDigits: places });
}

interface AbbreviatedNum {
    text: string;
    abbr: boolean;
}

export function abbreviatedCountOf(
    count: number,
    cutoff: number,
    str: string,
    inclusive = true,
    pluralForm?: string,
): AbbreviatedNum {
    const abbreviate = inclusive ? Math.abs(count) >= cutoff : Math.abs(count) > cutoff;
    const number = abbreviate ? displayNumberAbbr(count) : num(count);
    return { text: number + " " + Str.pluralForm(str, count, pluralForm), abbr: abbreviate };
}

/**
 * @deprecated We have {@link Str.countOf} that does the same thing. We should stop using this and
 *              replace usages of it with {@link Str.countOf} at some point.
 */
export function countOf(count: number, str: string, pluralForm?: string) {
    return num(count) + " " + Str.pluralForm(str, count, pluralForm);
}

export function countOfWithToBe(count: number, str: string, pluralForm?: string) {
    return `${countOf(count, str, pluralForm)} ${conjugateToBeFor(count)}`;
}

export function conjugateToBeFor(count: number) {
    return count === 1 ? "is" : "are";
}

/**
 * Returns formatted count (abbreviated if under cutoff) and whether the count was abbreviated.
 */
export function numOrAbbrAndResult(
    count: number,
    cutoff: number,
    inclusive = true,
): AbbreviatedNum {
    const abbreviate = inclusive ? Math.abs(count) >= cutoff : Math.abs(count) > cutoff;
    return {
        text: abbreviate ? displayNumberAbbr(count) : StringUtil.num(count),
        abbr: abbreviate,
    };
}

/**
 * Pretty prints the count if Math.abs(count) is below cutoff, otherwise abbreviates count.
 */
export function numOrAbbr(count: number, cutoff: number, inclusive = true) {
    return numOrAbbrAndResult(count, cutoff, inclusive).text;
}

/**
 * Returns -1, 0, 1, or NaN according to the sign of the given number.
 */
export function sign(x: number) {
    x = +x; // convert to a number
    if (x === 0 || isNaN(x)) {
        return x;
    }
    return x > 0 ? 1 : -1;
}

/**
 * Returns x if it is between min and max. Otherwise returns min when x < min and max when x > max.
 */
export function clamp(x: number, min = -Infinity, max = Infinity) {
    return Math.min(max, Math.max(min, x));
}

export interface Point {
    x: number;
    y: number;
}

/**
 * @param s string to ellipse
 * @param width max length of returned string, including trailing ellipses
 */
export function ellipsify(s: string, width: number) {
    if (s.length <= width) {
        return s;
    }
    return s.substring(0, width - 1) + E.ELIP;
}

/**
 * This differs slightly from JavaScript's built-in % operator: e.g.
 *
 * 12 % 10 == 2       == modulo(12, 10)
 *   but
 * -8 % 10 == -8 != 2 == modulo(-8, 10)
 */
export function modulo(a: number, b: number) {
    return ((a % b) + b) % b;
}

/**
 * computes a random string like what you might use for a password
 * @param length        of the out put
 * @param characters    list of letters that could appear in the string
 */
export function randomPassword(
    length = 12,
    characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
) {
    var out = "";
    for (var i = 0; i < length; i++) {
        out = out + characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return out;
}

export function lazy<T>(init: () => T): () => T {
    let internalInit: (() => T) | null = init;
    let res: T;
    return function () {
        if (internalInit) {
            res = internalInit();
            internalInit = null;
        }
        return res;
    };
}

/**
 * Convert a path from a list of strings to a single string
 */
export function pathToString(path: string[]) {
    // Generate a string using forward-slash delimiter by default, or backslash if necessary
    return path.join(path.some((str) => str && str.indexOf("/") !== -1) ? "\\" : "/");
}

export function pathToList(path: string) {
    const delimiter1 = path.indexOf("/");
    const delimiter2 = path.indexOf("\\");
    let delimiter: string;
    if (delimiter2 == -1) {
        delimiter = "/";
    } else if (delimiter1 == -1) {
        delimiter = "\\";
    } else {
        delimiter = delimiter1 < delimiter2 ? "/" : "\\";
    }
    return path.split(delimiter);
}

/**
 * Generates a range of numbers. By default, the end of the range is excluded.
 *
 * Examples:
 *   range(1, 5) ->       [1, 2, 3, 4]
 *   range(1, 5, true) -> [1, 2, 3, 4, 5]
 */
export function range(start: number, end: number, inclusive?: boolean) {
    const maybeLast = inclusive ? 1 : 0;
    const numElements = end - start + maybeLast;
    const result = [];
    for (let i = 0; i < numElements; i++) {
        result.push(start + i);
    }
    return result;
}

export function union<T>(s1: Set<T>, s2: Set<T>): Set<T> {
    const result = new Set<T>();
    s1.forEach((x) => result.add(x));
    s2.forEach((x) => result.add(x));
    return result;
}

/*
 * Return 1 iff a, -1 iff b, else 0;
 */
export function boolCompare(a: boolean, b: boolean): number {
    return Number(a) - Number(b);
}

export function finishTweens(toFinish: string | HTMLElement | gsap.core.Animation[]) {
    const animations =
        Is.string(toFinish) || toFinish instanceof HTMLElement
            ? gsap.getTweensOf(toFinish)
            : toFinish;
    animations.forEach((anim) => {
        // totalProgress(1) will call onComplete, don't call twice
        if (anim.totalProgress() < 1) {
            anim.totalProgress(1);
        }
    });
}

/**
 * There's a preexisting function that does this but its not compatible with some of the browser
 * versions we support.
 *
 * This function takes the query string from the current url (i.e.
 * ?utm_medium=email&utm_source=everlaw) and extracts the relavent information into an object (i.e.
 * { utm_medium: emal, utm_source: everalw }).
 */
export function extractQueryParams(): { [key: string]: string } {
    const queryString = window.location.search;
    let paramObject: { [key: string]: string } = {};
    queryString
        .substr(1)
        .split("&")
        .forEach(function (param) {
            let pair = param.split("=");
            if (pair[0].length > 0) {
                paramObject[pair[0]] = pair[1];
            }
        });
    return paramObject;
}

/**
 * Consumable byte-buffer that wraps a raw DataView.  This is meant to make it easier to work
 * with streams of binary data.
 */
export class ByteStream {
    private cursor: number = 0;

    constructor(
        private data: DataView,
        public readonly isLittleEndian: boolean = false,
    ) {}

    static fromBase64(encoded: string, isLittleEndian: boolean = false): ByteStream {
        const decoded = window.atob(encoded);
        const data = new DataView(new ArrayBuffer(decoded.length));
        for (let i = 0; i < decoded.length; ++i) {
            data.setUint8(i, decoded.charCodeAt(i));
        }
        return new ByteStream(data, isLittleEndian);
    }

    consumeInt8(): number {
        const i = this.data.getInt8(this.cursor);
        this.cursor += 1;
        return i;
    }

    consumeInt32(): number {
        const i = this.data.getInt32(this.cursor, this.isLittleEndian);
        this.cursor += 4;
        return i;
    }

    consumeFloat32(): number {
        const f = this.data.getFloat32(this.cursor, this.isLittleEndian);
        this.cursor += 4;
        return f;
    }

    consumeFloat64(): number {
        const f = this.data.getFloat64(this.cursor, this.isLittleEndian);
        this.cursor += 8;
        return f;
    }

    getCursor(): number {
        return this.cursor;
    }
}

/**
 * window.requestIdleCallback is nice because it allows us to do low priority work without
 * blocking rendering or other high priority work. However, some archaic browsers don't support it
 * (https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback#Browser_compatibility)
 * so we use setTimeout as a fallback. Returns a cancel handler.
 */
export function doBackgroundWork(work: () => any): () => void {
    const wind = window as any; // cast to prevent TS error
    if (Is.func(wind.requestIdleCallback)) {
        const id = wind.requestIdleCallback(work);
        return () => wind.cancelIdleCallback(id);
    } else {
        const id = setTimeout(work, 0);
        return () => clearTimeout(id);
    }
}

export function clearSelectedText() {
    document.getSelection()?.removeAllRanges();
}

/**
 * Regular bitwise operations in TypeScript discard the top 32 bits of information. This function
 * enables the bitwise AND (&) operator on more than 32-bit TypeScript numbers without running into
 * overflow/underflow errors.
 * This was made to interact with only 53 bits of data (since TypeScript numbers are precise to 53
 * bits). This could be extended in the future to make use of all 64 bits of information, but that
 * would probably better be done behind the implementation of a bitmask in TypeScript.
 * This also currently errors on negative numbers.
 */
export function bitwiseAND53(a: number, b: number) {
    if (typeof a !== "number" || typeof b !== "number") {
        throw new TypeError("cannot bitwise AND non-number values");
    } else if (a < 0 || b < 0) {
        throw new Error("bitwise AND on negative numbers is not implemented");
    } else if (a >= 2 ** 53 || b >= 2 ** 53) {
        throw new Error("bitwise AND on numbers greater than (2^53) - 1 is not implemented");
    }
    const lowerA = modulo(a, 2 ** 31);
    const lowerB = modulo(b, 2 ** 31);
    const upperA = ~~(a / 2 ** 31);
    const upperB = ~~(b / 2 ** 31);
    return (upperA & upperB) * 2 ** 31 + (lowerA & lowerB);
}

/**
 * Simple utility to assert that a value is never reached. This is useful for exhaustiveness checks
 * in places like switch statements. For example, this can be placed in the `default` case of a
 * switch statement to ensure that all possible cases are handled.
 * @param val The value that should never be reached.
 */
export function assertNever(val: never): never {
    throw new Error("Unexpected value: " + val);
}

/**
 * Returns next index when idx is modified by delta in a list of size listSize.
 * For example:
 * - (1, 0, 5) returns 1 (unchanged index).
 * - (0, -1, 5) returns 4 (wrapping from first index to last index).
 * - (4, 1, 5) returns 0 (wrapping from last to first index).
 * - (1, 1, 5) returns 2 (incrementing to next index).
 * @param idx The index of the list - which can be out of bounds or negative.
 * @param delta The change applied to idx before computing the next index.
 * @param listSize The total size of the list.
 */
export function getNextWrappingIndex(idx: number, delta: number, listSize: number): number {
    return (((idx + delta) % listSize) + listSize) % listSize;
}

/**
 * Dojo's implementation of {@link https://github.com/dojo/dojo/blob/185a4fb314de482a1b6b5668095b998da9c1b58f/_base/lang.js#L392 lang.delegate()}.
 * This is copied into our codebase for backwards compatibility and should be removed the moment
 * it's no longer needed.
 * @param obj The object to delegate to for properties not found directly on the return object or in
 * props.
 * @param props An object containing properties to assign to the returned object.
 * @deprecated Don't use this ancient hack for any new code. Consider using object spread syntax
 * instead.
 */
export function dojoDelegate<O, P extends Record<string, unknown>>(obj: O, props?: P): O & P {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    function TempConstructor() {}
    TempConstructor.prototype = obj;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = new TempConstructor();
    TempConstructor.prototype = null;
    if (props) {
        Object.assign(result, props);
    }
    return result;
}
