export function isLocal() {
    return JSP_PARAMS.Server.isLocal;
}

export function isDev() {
    return JSP_PARAMS.Server.env === "dev";
}

export function isLive() {
    return JSP_PARAMS.Server.env === "live";
}

export function isFedRamp() {
    return JSP_PARAMS.Server.isFedRamp;
}

export function isUS() {
    return JSP_PARAMS.Server.vpc === "US";
}

export function isAU() {
    return JSP_PARAMS.Server.vpc === "AU";
}

export function isCA() {
    return JSP_PARAMS.Server.vpc === "CA";
}

export function isSbFree() {
    return JSP_PARAMS.Server.isSbFree;
}

export function isUK() {
    return JSP_PARAMS.Server.vpc === "UK";
}

export function isEU() {
    return JSP_PARAMS.Server.vpc === "EU";
}

export function isGoogle() {
    return JSP_PARAMS.Server.vpc === "GOOGLE";
}

export function isDOJ() {
    return JSP_PARAMS.Server.vpc === "DOJ";
}

export function isFED() {
    return JSP_PARAMS.Server.vpc === "FED";
}

export function isUSAO() {
    return JSP_PARAMS.Server.vpc === "USAO";
}

/**
 * See AwsPlacement#isUSRegion.
 */
export function isUSRegion(): boolean {
    return JSP_PARAMS.Server.region.startsWith("us-");
}

export function containsClientData(): boolean {
    return JSP_PARAMS.Server.containsClientData;
}

export function isMarketingOptOutEnabled(): boolean {
    return JSP_PARAMS.Server.marketingOptOutEnabled;
}

export function isImageProxyEnabled(): boolean {
    return JSP_PARAMS.Server.imageProxyEnabled;
}

export function isHeapAnalyticsEnabled(): boolean {
    return JSP_PARAMS.Server.heapAnalyticsEnabled;
}

export function isFeatureInstance(): boolean {
    return JSP_PARAMS.Server.vpc === "Feature";
}

export enum AWSRegion {
    US_EAST_1_N_VIRGINIA = "us-east-1",
    US_WEST_1_N_CALIFORNIA = "us-west-1",
    US_WEST_2_OREGON = "us-west-2",
    AP_NORTHEAST_1_TOKYO = "ap-northeast-1",
    AP_NORTHEAST_2_SEOUL = "ap-northeast-2",
    AP_NORTHEAST_3_OSAKA_LOCAL = "ap-northeast-3",
    AP_SOUTH_1_MUMBAI = "ap-south-1",
    AP_SOUTHEAST_1_SINGAPORE = "ap-southeast-1",
    AP_SOUTHEAST_2_SYDNEY = "ap-southeast-2",
    CA_CENTRAL_1 = "ca-central-1",
    CN_NORTH_1_BEIJING = "cn-north-1",
    CN_NORTHWEST_1_NINGXIA = "cn-northwest-1",
    EU_CENTRAL_1_FRANKFURT = "eu-central-1",
    EU_WEST_1_IRELAND = "eu-west-1",
    EU_WEST_2_LONDON = "eu-west-2",
    EU_WEST_3_PARIS = "eu-west-3",
    SA_EAST_1_SAO_PAULO = "sa-east-1",
    US_GOV_EAST_1 = "us-gov-east-1",
    US_GOV_WEST_1 = "us-gov-west-1",
}
